import { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { CardImage } from '../components/CardImage';
import { CardItem } from '../components/CardItem';
import { getPageData } from '../DataService';

function Home() {
    const [state, setState] = useState({ notices: [], cardDtos: [], isLoading: false });

    useEffect(() => {
        async function getData() {
            setState({ notices: [], cardDtos: [], isLoading: true });

            var cardItems = await getPageData('home');

            var noticeItems = await getPageData('notice');

            setState({ notices: noticeItems, cardDtos: cardItems, isLoading: false });
        }
        getData();
    }, []);


    return (
        <Container>
            {
                state.isLoading ?
                    <div className="text-center" style={{ marginTop: '200px' }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    : ''
            }

            {
                state.notices && state.notices.map((path, index) =>
                    <CardImage key={index} path={path} cardKey={index} />
                )
            }

            {
                state.cardDtos && state.cardDtos.map((item, index) =>
                    <CardItem key={index} cardDto={item as CardDto} cardKey={index} />
                )
            }
        </Container>
    );
}

export default Home;