import { Container } from 'react-bootstrap';

function Announcement() {
    return (
        <Container>

        </Container>
    );
}

export default Announcement;