import { Route, Switch } from "react-router-dom";
import Announcement from './pages/Announcement';
import Editor from './pages/Editor';
import Home from './pages/Home';
import Photos from './pages/Photos';
import PageNotFound from './PageNotFound';
import Navigation from "./Navigation";
import { Image } from "react-bootstrap";

function App() {
  return (
    <>
      <Image width={'100%'} src={process.env.REACT_APP_ASSET + '/images/banner.jpg'} fluid />
      <Navigation />

      <Switch>
        <Route path="/announcement" component={Announcement} />
        <Route path="/photos" component={Photos} />
        <Route path="/editor" component={Editor} />
        <Route path="/" exact={true} component={Home} />
        <Route path="/404" component={PageNotFound} />
        {/* <Redirect to="/404" /> */}
      </Switch>
    </>
  );
}

export default App;
