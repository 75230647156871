import React from 'react';
import { ListGroup } from 'react-bootstrap';

interface Props {
    cardFile: CardFile;
}

export const CardFileItem: React.FC<Props> = ({ cardFile }) => {
    return (
        <ListGroup.Item
            target="_blank"
            rel="noopener noreferrer"
            action className="doc-link"
            href={process.env.REACT_APP_ASSET + cardFile.file}
        >
            {
                cardFile.type === 'pdf' && <img style={{ marginRight: '10px' }} src={process.env.REACT_APP_ASSET + '/images/file-earmark-pdf.svg'} alt="" width="20" height="20" />
            }
            {
                cardFile.type === 'image' && <img src={process.env.REACT_APP_ASSET + cardFile.thumbnail} alt="" width={cardFile.width} height={cardFile.height} />
            }

            {
                cardFile.display
            }
        </ListGroup.Item>
    );
};