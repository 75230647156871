import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navigation() {
    return (
        <Navbar bg="light" expand="lg" className="navbar-container">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav className="me-auto justify-content-center" style={{ flex: 1 }}>
                        <Nav.Link as={Link} to="/">முகப்பு</Nav.Link>
                        <Nav.Link as={Link} to="/editor">ஆசிரியர் பக்கம்</Nav.Link>
                        <Nav.Link as={Link} to="/announcement">அறிவிப்புகள்</Nav.Link>
                        <Nav.Link as={Link} to="/photos">புகைப்படங்கள்</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;