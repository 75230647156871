
export const getPageData = async (page: string): Promise<any> => {
    console.log('Get page data for: ', page);

    const pageKey = `Page-${page}`;
    const pageKeyAsOf = pageKey + 'AsOf';

    var storedData = localStorage.getItem(pageKey);
    var storedAsOf = localStorage.getItem(pageKeyAsOf);

    if (storedData !== null && storedAsOf !== null) {
        var mins = (new Date().getTime() - parseInt(storedAsOf)) / 6e4;
        if (mins < 30) {
            //console.log('Get page data from storage for: ', page, hours);
            return JSON.parse(storedData);
        }
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_PAGEAPI}/PageDataFunction?page=${page}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-functions-key': process.env.REACT_APP_PAGEAPI_KEY ?? ''
            }
        });

        const data = await response.json();

        localStorage.setItem(pageKey, JSON.stringify(data));
        localStorage.setItem(pageKeyAsOf, new Date().getTime().toString());

        return data;

    } catch (error) {
        console.log(`Error loading data for page: ${page}`);
    }

    return null;
}