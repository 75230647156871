import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { CardFileItem } from './CardFileItem';

interface Props {
    cardDto: CardDto;
    cardKey: number;
}

export const CardItem: React.FC<Props> = ({ cardDto, cardKey }) => {
    return (
        <Row className="justify-content-md-center general-block-item" key={cardKey}>
            <Col md="auto">
                <Card style={{ minWidth: '25rem' }}>
                    {
                        cardDto.header && cardDto.header !== '' && <Card.Header>{cardDto.header}</Card.Header>
                    }

                    <Card.Body>
                        {
                            cardDto.title && cardDto.title !== '' && <Card.Title>{cardDto.title}</Card.Title>
                        }

                        {
                            cardDto.subtitle && cardDto.subtitle !== '' && <Card.Title>{cardDto.subtitle}</Card.Title>
                        }

                        {
                            cardDto.files && cardDto.files.length > 0 &&
                            <ListGroup>
                                {
                                    cardDto.files.map((fileItem, fileIndex) => <CardFileItem key={fileIndex} cardFile={fileItem} />)
                                }
                            </ListGroup>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
