import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { CardItem } from '../components/CardItem';
import { getPageData } from '../DataService';

function Editor() {
    const [cardDtos, setCardDtos] = useState([]);

    useEffect(() => {
        async function getData() {
            setCardDtos(await getPageData('editor'));
        }
        getData();
    }, []);

    return (
        <Container>
            {
                cardDtos && cardDtos.map((item, index) =>
                    <CardItem key={index} cardDto={item as CardDto} cardKey={index} />
                )
            }
        </Container>
    );
}

export default Editor;