import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { PhotoAlbumComponent } from '../components/PhotoAlbum';
import { getPageData } from '../DataService';

function Photos() {
    const [albums, setAlbums] = useState([]);

    useEffect(() => {
        async function getData() {
            setAlbums(await getPageData('photos'));
        }
        getData();
    }, []);

    return (
        <Container>
            {
                albums && albums.map((item, index) =>
                    <PhotoAlbumComponent key={index} album={item as PhotoAlbum} />
                )
            }
        </Container>
    );
}

export default Photos;