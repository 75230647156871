import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

interface Props {
    path: string;
    cardKey: number;
}

export const CardImage: React.FC<Props> = ({ path, cardKey }) => {
    return (
        <Row className="justify-content-md-center general-block-item" key={cardKey}>
            <Col md="auto">
                <Card style={{ minWidth: '25rem' }}>
                    <Card.Body>
                        {
                            <img style={{ margin: '15px' }} src={process.env.REACT_APP_ASSET + path} alt="" />
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
