import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy, Pagination, Navigation } from 'swiper';

SwiperCore.use([Lazy, Pagination, Navigation]);

interface Props {
    album: PhotoAlbum;
}

export const PhotoAlbumComponent: React.FC<Props> = ({ album }) => {
    const getPhotos = () => {
        const albums = [];
        const size = 10;

        for (let i = 1; i <= album.count; i += size) {
            const chunk = [];
            for (let j = 0; j < size; j++) {
                let k = i + j;
                if (k <= album.count) {
                    chunk.push(process.env.REACT_APP_ASSET + album.path.replace('{#}', k.toString()));
                }
            }
            albums.push(chunk);
        }
        return albums;
    };

    const pagination = {
        "clickable": true,
        "renderBullet": function (index: number, className: string) {
            return `<span class="${className}">${index + 1}</span>`;
        }
    };
    const navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    };

    return (
        <Row className="justify-content-md-center general-block-item" key={album.title}>
            <Col>
                <Card>
                    <Card.Header>{album.title}</Card.Header>

                    <Card.Body>
                        {
                            getPhotos().map((itemList, i) =>
                                <Swiper key={i} lazy={true} pagination={pagination} navigation={navigation} className="mySwiper">
                                    {
                                        itemList.map((item, index) =>
                                            <SwiperSlide key={index}>
                                                <img data-src={item} className="swiper-lazy" alt="" />
                                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                            </SwiperSlide>
                                        )
                                    }
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                </Swiper>
                            )
                        }
                    </Card.Body>
                </Card>
            </Col>

        </Row>
    );
};